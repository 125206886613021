import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Home.css';
import nesconLogo from '../assets/images/neoscon-logo-new.png';
import logo1 from '../assets/images/logo1-1.png';
import logo2 from '../assets/images/logo2-2.png';
import logo3 from '../assets/images/logo3.png';

const Home = () => {
  return (
    <Container fluid className="home">
      {/* Row for the three logos */}
      <Row className="justify-content-center align-items-center text-center logo-row">
        <Col xs={4} md={2}>
          <img src={logo1} alt="Logo 1" className="top-logo img-fluid" />
        </Col>
        <Col xs={4} md={2}>
          <img src={logo2} alt="Logo 2" className="top-logo img-fluid" />
        </Col>
        <Col xs={4} md={2}>
          <img src={logo3} alt="Logo 3" className="top-logo img-fluid" />
        </Col>
      </Row>

      {/* Existing content */}
      <Row className="justify-content-center align-items-center text-center logos">
        <Col xs={12} md={8}>
          <h1 className="title">SIKKIM ORTHOPAEDICS CHAPTER</h1>
          <p className="presents-text">PRESENTS</p>
          <img src={nesconLogo} alt="Nescon 2024 Logo" className="nescon-logo img-fluid" />
        </Col>
      </Row>
      <Row className="justify-content-center text-center">
        <Col xs={12} md={10}>
          <p className="description">
            Neoscon 2025, is the 38th Annual Conference of the 
            North East Regional Orthopaedics Surgeons Association (NEROSA) organized by Sikkim Orthopaedic Chapter, aimed at bringing together the brightest minds in healthcare to discuss and explore the latest innovations and challenges in the field.
          </p>
        </Col>
      </Row>
      <div className="col-md-12 col-12 text-center text-md-right">
        <div className="home-button">
          <Link to="/registration" className="btn btn-primary register-btn">
            Register Now
          </Link>
        </div>
      </div>
      <div className="flash-message">
        <span className="moving-text">Registration is open. Abstract submission date has been extended till January 15th 2025.</span>
      </div>
      <div className="flash-message">
        <span className="moving-text">Senior citizens above 65 years of age, kindly contact Dr. Shreel Koirala for Registration.</span>
      </div>

    </Container>
  );
};

export default Home;
